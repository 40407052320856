<template>
  <CModal
    submit-form="manager-invite-form"
    v-model="show"
    :submit-loading="loading"
  >
    <template v-slot:header>
      <template>
        {{ $t('createInvite') }}
      </template>
    </template>
    <CForm 
      id="manager-invite-form" 
      @submit="createInvite" 
      :error-fields-response="errorFieldsResponse"
      custom-save-button
    >
      <CTextField
        v-model="managerInvite.email"
        :label="$t('email')"
        type="email"
        autocomplete="email"
        required
      />
      <CSelect
        v-model="managerInvite.role"
        :label="$t('role')"
        disabled
      >
        <option value="admin">{{ $t('roles.admin') }}</option>
        <option value="manager">{{ $t('roles.manager') }}</option>
        <option value="dispatcher">{{ $t('roles.dispatcher') }}</option>
        <option value="driver">{{ $t('roles.driver') }}</option>
      </CSelect>
      <CTextField
        v-model="managerInvite.phone"
        :label="$t('phone')"
        type="tel"
        autocomplete="tel"
        required
      />
    </CForm>
    <template v-slot:submit>
      {{ $t('save') }}
    </template>
    <CMessage v-if="emailAlreadyUsed" variant="danger">
      {{ $t('emailAlreadyUsed') }}
    </CMessage>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_MANAGER_INVITE } from '@/store/actionTypes';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      managerInvite: {
        email: '',
        role: 'admin',
        phone: '',
      },
      emailAlreadyUsed: false,
      errorFieldsResponse: null,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    loading() {
      return this.$store.state.managerInvites.submitLoading;
    },
  },

  watch: {
    show() {
      this.managerInvite = {
        email: '',
        role: 'admin',
        phone: '',
      };
    },
    managerInvite: {
      deep: true,
      handler() {
        this.emailAlreadyUsed = false;
        this.errorFieldsResponse = null;
      },
    },
  },

  methods: {
    ...mapActions([CREATE_MANAGER_INVITE]),

    async createInvite() {
      try {
        await this.createManagerInvites(this.managerInvite);
        this.$emit('input', false);
        this.show = false;
      } catch (e) {
        if (e.response.data) {
          const errCode = e.response.data.code;
          if (errCode === 'E_ALREADY_INVITED' || errCode === 'E_EMAIL_ALREADY_USED') {
            this.emailAlreadyUsed = true;
          }
          if (errCode === 'E_VALIDATION_FAILURE') {
            this.errorFieldsResponse = e.response;
          }
        }
        console.log(e);
      }
    },
  },
};
</script>