<template>
  <div>
    <MangersList :per-page="150" v-if="!loading" />
    <ManagerInviteList class="mt-10" :per-page="150" v-if="!loading" />
    <div v-else class="h-[300px] flex items-center justify-center">
      <CLoadingSpinner class="w-6" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_MANAGERS, GET_MANAGER_INVITES } from '@/store/actionTypes';
import MangersList from '@/ui/components/Merchant/Managers/ManagerList.vue';
import ManagerInviteList from '@/ui/components/Merchant/Managers/ManagerInviteList.vue';

export default {
  components: {
    MangersList,
    ManagerInviteList,
  },

  data: () => ({
    inviteModel: false,
  }),

  computed: {
    loading() {
      return this.$store.state.managerInvites.loading && this.$store.state.managers.loading;
    },
  },

  created() {
    this.getManagers();
    this.getManagerInvites();
  },

  methods: {
    ...mapActions([GET_MANAGERS, GET_MANAGER_INVITES]),
  },
};
</script>
