var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"overflow-hidden"},[_c('div',{staticClass:"flex justify-between p-4 md:px-6 border-b"},[_c('CTypo',{attrs:{"tstyle":"title3"}},[_vm._v(" "+_vm._s(_vm.$t('invites'))+" ")]),_c('CButton',{attrs:{"variant":"primary","size":"small","icon-left":"plus-circle"},on:{"click":function($event){_vm.inviteModel = true}}},[_vm._v(" "+_vm._s(_vm.$t('invite'))+" ")])],1),_c('CTable',_vm._b({ref:"table",attrs:{"data":_vm.allManagerInvites,"sticky-header":"","box":_vm.box,"mobile-cards":"","mobile-breakpoint":"440px"}},'CTable',Object.assign({}, _vm.$attrs),false),[_c('o-table-column',{attrs:{"label":_vm.$t('email'),"width":"250","sortable":"","field":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email)+" ")]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('role'),"width":"125","sortable":"","field":"role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CBadge',[_vm._v(" "+_vm._s(_vm.$t(("roles." + (row.role))))+" ")])]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('phone'),"width":"150","sortable":"","field":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phone)+" ")]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('actions'),"width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CButton',{attrs:{"variant":"primary","size":"small","icon-left":"mail-outline","loading":_vm.resendingInviteId === row.id},on:{"click":function($event){return _vm.resendInvite(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('resend'))+" ")]),_c('CButton',{staticClass:"ml-2",attrs:{"variant":"danger","size":"small","icon-left":"trash-outline","loading":_vm.deletingInviteId === row.id},on:{"click":function($event){return _vm.deleteInvite(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])]}}])})],1)],1),_c('Portal',{attrs:{"to":"overlays"}},[_c('ManagerInviteModel',{model:{value:(_vm.inviteModel),callback:function ($$v) {_vm.inviteModel=$$v},expression:"inviteModel"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }