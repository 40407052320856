var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"overflow-hidden"},[_c('div',{staticClass:"flex justify-between p-4 md:px-6 border-b"},[_c('CTypo',{attrs:{"tstyle":"title3"}},[_vm._v(" "+_vm._s(_vm.$t('managers'))+" ")])],1),_c('CTable',_vm._b({ref:"table",attrs:{"data":_vm.allManagers,"sticky-header":"","box":_vm.box,"mobile-cards":"","mobile-breakpoint":"440px","hoverable":""},on:{"row-click":_vm.openManagerUpdateModal}},'CTable',Object.assign({}, _vm.$attrs),false),[_c('o-table-column',{attrs:{"label":_vm.$t('email'),"width":"250","sortable":"","field":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.email)+" ")]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('role'),"width":"125","sortable":"","field":"role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CBadge',[_vm._v(" "+_vm._s(_vm.$t(("roles." + (row.role))))+" ")])]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('firstName'),"width":"125","sortable":"","field":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.firstName)+" ")]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('lastName'),"width":"125","sortable":"","field":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.lastName)+" ")]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('phone'),"width":"150","sortable":"","field":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phone)+" ")]}}])}),_c('o-table-column',{attrs:{"label":_vm.$t('active'),"width":"150","sortable":"","position":"centered","field":"manager.active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.active)?_c('CBadge',{attrs:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.$t('active'))+" ")]):_c('CBadge',{attrs:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('unactive'))+" ")])]}}])})],1)],1),_c('ManagerUpdateModal',{model:{value:(_vm.showManagerUpdateModel),callback:function ($$v) {_vm.showManagerUpdateModel=$$v},expression:"showManagerUpdateModel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }