
<template>
  <!--eslint-disable vue/attribute-hyphenation-->
  <div>
    <CCard class="overflow-hidden">
      <div class="flex justify-between p-4 md:px-6 border-b">
        <CTypo tstyle="title3">
          {{ $t('managers') }}
        </CTypo>
      </div>
      <CTable
        ref="table"
        :data="allManagers"
        sticky-header
        :box="box"
        mobile-cards
        mobile-breakpoint="440px"
        v-bind="{ ...$attrs }"
        hoverable
        @row-click="openManagerUpdateModal"
      >
        <o-table-column
          :label="$t('email')"
          width="250"
          sortable
          v-slot="{ row }"
          field="email"
        >
          {{ row.user.email }}
        </o-table-column>
        <o-table-column
          :label="$t('role')"
          width="125"
          sortable
          v-slot="{ row }"
          field="role"
        >
          <CBadge>
            {{ $t(`roles.${row.role}`) }}
          </CBadge>
        </o-table-column>
        <o-table-column
          :label="$t('firstName')"
          width="125"
          sortable
          v-slot="{ row }"
          field="firstName"
        >
          {{ row.user.firstName }}
        </o-table-column>
        <o-table-column
          :label="$t('lastName')"
          width="125"
          sortable
          v-slot="{ row }"
          field="lastName"
        >
          {{ row.user.lastName }}
        </o-table-column>
        <o-table-column
          :label="$t('phone')"
          width="150"
          sortable
          v-slot="{ row }"
          field="phone"
        >
          {{ row.phone }}
        </o-table-column>
        <o-table-column
          :label="$t('active')"
          width="150"
          sortable
          position="centered"
          v-slot="{ row }"
          field="manager.active"
        >
          <CBadge v-if="row.active" color="green"> {{ $t('active') }} </CBadge>
          <CBadge v-else color="red"> {{ $t('unactive') }} </CBadge>
        </o-table-column>
      </CTable>
    </CCard>
    <ManagerUpdateModal v-model="showManagerUpdateModel" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ALL_MANAGERS } from '@/store/gettersTypes';
import { OPEN_MANAGER } from '@/store/mutationsTypes';
import ManagerUpdateModal from './ManagerUpdateModal.vue';

export default {
  components: {
    ManagerUpdateModal,
  },

  props: {
    box: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      showManagerUpdateModel: false,
    };
  },

  computed: {
    ...mapGetters([ALL_MANAGERS]),
  },

  methods: {
    ...mapMutations([OPEN_MANAGER]),
    openManagerUpdateModal(manager) {
      this[OPEN_MANAGER](manager.id);
      this.showManagerUpdateModel = true;
    },
  },
};
</script>
