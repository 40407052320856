
<template>
  <!--eslint-disable vue/attribute-hyphenation-->
  <div>
    <CCard class="overflow-hidden">
      <div class="flex justify-between p-4 md:px-6 border-b">
        <CTypo tstyle="title3">
          {{ $t('invites') }}
        </CTypo>

        <CButton
          variant="primary"
          size="small"
          icon-left="plus-circle"
          @click="inviteModel = true"
        >
          {{ $t('invite') }}
        </CButton>
      </div>

      <CTable
        ref="table"
        :data="allManagerInvites"
        sticky-header
        :box="box"
        mobile-cards
        mobile-breakpoint="440px"
        v-bind="{ ...$attrs }"
      >      
        <o-table-column
          :label="$t('email')"
          width="250"
          sortable
          v-slot="{ row }"
          field="email"
        >
          {{ row.email }}
        </o-table-column>
        <o-table-column
          :label="$t('role')"
          width="125"
          sortable
          v-slot="{ row }"
          field="role"
        >
          <CBadge>
            {{ $t(`roles.${row.role}`) }}
          </CBadge>
        </o-table-column>
        <o-table-column
          :label="$t('phone')"
          width="150"
          sortable
          v-slot="{ row }"
          field="phone"
        >
          {{ row.phone }}
        </o-table-column>
        <o-table-column
          :label="$t('actions')"
          v-slot="{ row }"
          width="300"
        >
          <CButton
            variant="primary"
            size="small"
            icon-left="mail-outline"
            @click="resendInvite(row.id)"
            :loading="resendingInviteId === row.id"
          >
            {{ $t('resend') }}  
          </CButton>
          <CButton
            variant="danger"
            size="small"
            icon-left="trash-outline"
            @click="deleteInvite(row.id)"
            :loading="deletingInviteId === row.id"
            class="ml-2"
          >
            {{ $t('delete') }}  
          </CButton>
        </o-table-column>
      </CTable>
    </CCard>
    <Portal to="overlays">
      <ManagerInviteModel v-model="inviteModel" />
    </Portal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ALL_MANAGER_INVITES } from '@/store/gettersTypes';
import { RESEND_MANAGER_INVITE, DELETE_MANAGER_INVITE } from '@/store/actionTypes';
import ManagerInviteModel from '@/ui/components/Merchant/Managers/ManagerInviteModel.vue';
import { SET_SUCCESS_NOTIFICATION } from '@/store/mutationsTypes';

export default {
  components: {
    ManagerInviteModel,
  },

  props: {
    box: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      inviteModel: false,
      resendingInviteId: null,
      deletingInviteId: null,
    };
  },

  computed: {
    ...mapGetters([ALL_MANAGER_INVITES]),
  },

  methods: {
    ...mapActions([RESEND_MANAGER_INVITE, DELETE_MANAGER_INVITE]),
    ...mapMutations([SET_SUCCESS_NOTIFICATION]),
    async resendInvite(id) {
      this.resendingInviteId = id;
      await this[RESEND_MANAGER_INVITE](id);
      setTimeout(() => {
        this.resendingInviteId = null;
      }, 500);
    },
    deleteInvite(id) {
      this.deletingInviteId = id;
      setTimeout(async () => {
        await this[DELETE_MANAGER_INVITE](id);
        this.deletingInviteId = null;
      }, 200);
    },
  },
};
</script>
