<template>
  <CModal 
    submit-form="manager-update-form"
    :submit-loading="loading"
    v-model="show"
    :submit-disabled="editsHimself"
  >
    <template v-slot:header>
      <template>
        {{ $t('editManager') }}
      </template>
    </template>
    <CAlert v-if="editsHimself" variant="warning">
      {{ $t('canNotEditOwnManagerData') }}
    </CAlert>
    <CForm
      id="manager-update-form" 
      @submit="updateManagerData"
      :error-fields-response="errorFieldsResponse"
      custom-save-button
    >
      <CSelect
        v-model="updateData.role"
        :label="$t('role')"
        required
        disabled
      >
        <option value="admin">{{ $t('roles.admin') }}</option>
        <option value="manager">{{ $t('roles.manager') }}</option>
        <option value="dispatcher">{{ $t('roles.dispatcher') }}</option>
        <option value="driver">{{ $t('roles.driver') }}</option>
      </CSelect>
      <CTextField
        v-model="updateData.phone"
        :label="$t('phone')"
        type="tel"
        autocomplete="tel"
        required
        :disabled="editsHimself"
      />
      <CSwitch 
        v-model="updateData.active" 
        :disabled="editsHimself" 
        class="mb-3"
      >
        {{ $t('active') }}
      </CSwitch>
    </CForm>
    <template v-slot:submit>
      {{ $t('save') }}
    </template>
  </CModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CURRENT_MANAGER, THIS_USER } from '@/store/gettersTypes';
import { UPDATE_MANAGER } from '@/store/actionTypes';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      updateData: {
        role: '',
        phone: '',
        active: true,
      },
      errorFieldsResponse: null,
    };
  },

  computed: {
    ...mapGetters([CURRENT_MANAGER, THIS_USER]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    loading() {
      return this.$store.state.managers.updateLoading;
    },
    editsHimself() {
      return this.thisUser.id === this.currentManager?.user.id;
    },
  },

  watch: {
    value() {
      this.updateData = {
        role: this.currentManager.role,
        phone: this.currentManager.phone,
        active: this.currentManager.active,
      };
    },
    updateData: {
      handler() {
        this.errorFieldsResponse = null;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([UPDATE_MANAGER]),
    async updateManagerData() {
      try {
        await this.updateManager({ id: this.currentManager.id, body: this.updateData });
        this.$emit('input', false);
      } catch (e) {
        if (e.response.data) {
          const errCode = e.response.status;
          if (errCode === 422) {
            this.errorFieldsResponse = e.response;
          }
        } else {
          throw e;
        }
      }
    },
  },
};
</script>